import type {} from '@batch.com/cordova-plugin';
import { Capacitor, registerPlugin } from '@capacitor/core';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import useAuthState from '@carers/hooks/useAuthState';
import { PLATFORM } from '@shared/constants/appConstants';
import { init } from '@shared/utils/Notifications';

interface BatchPlugin {
  fontOverride(): Promise<void>;
}

const BatchSDK = () => {
  const batchPlugin = registerPlugin<BatchPlugin>('batchPlugin');

  const platform = Capacitor.getPlatform();
  const isWebPlatform = platform === PLATFORM.WEB;

  const { t } = useTranslation('app');

  const { accessToken } = useAuthState();

  const currentPath = window.location.href;

  const isFacilitiesAppPage = currentPath.includes('facilities');
  const webNotificationModalContent = !isFacilitiesAppPage
    ? {
        text: t('carers.common.messages.notifications.activateNotificationText'),
        positiveSubBtnLabel: t(
          'carers.common.messages.notifications.confirmActivateNotificationsButtonLabel',
        ),
        negativeBtnLabel: t(
          'carers.common.messages.notifications.declineActivateNotificationsButtonLabel',
        ),
      }
    : {
        text: t('facilities.common.messages.notifications.activateNotificationText'),
        positiveSubBtnLabel: t(
          'facilities.common.messages.notifications.confirmActivateNotificationsButtonLabel',
        ),
        negativeBtnLabel: t(
          'facilities.common.messages.notifications.declineActivateNotificationsButtonLabel',
        ),
      };

  const batchFontOverride = useCallback(async () => {
    if (!isWebPlatform) {
      await batchPlugin.fontOverride();
    }
  }, []);

  useEffect(() => {
    init(webNotificationModalContent, accessToken);
    batchFontOverride().catch((err) => {
      throw err;
    });
  }, []);

  return null;
};

export default BatchSDK;
